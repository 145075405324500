import Header from '../components/Header';
import TopTracks from '../components/TopTracks';
import Albums from '../components/Albums';
import Footer from '../components/Footer';

export default function App() {
  return (
    <>
      <Header />
      <TopTracks />
      <Albums />
      <Footer />
    </>
  );
}