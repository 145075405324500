import { useState } from 'react';
import { useQuery } from 'react-query';
import parse from 'html-react-parser';
import cn from 'classnames';
import { fetchAlbums } from '../services/spotify';
import { AlbumType } from '../types/spotify';
import { albumTypes } from '../utils/lib';
import Album from './Album';
import Loading from './Loading';

// CSS
import style from '../css/modules/Albums.module.scss';

export default function Albums() {
  const [includeGroups, setIncludeGroups] = useState<AlbumType | undefined>(undefined);
  const { data, isLoading } = useQuery('albumsData', fetchAlbums);

  const handleClickAlbumType = (key?: AlbumType) => () => setIncludeGroups(key);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <section className={cn(style.container, 'py-5')}>
      <div className="container">
        <h2 className="title-1">Discografia</h2>
        <div className={style.container__filters}>
          <button
            onClick={handleClickAlbumType()}
            className={cn(style.button, !includeGroups && style.active)}
          >
            Tutti
          </button>
          {albumTypes.map(({ key, value_plural }, index) => (
            <button
              key={index}
              onClick={handleClickAlbumType(key)}
              className={cn(style.button, includeGroups === key && style.active)}
            >
              {parse(value_plural)}
            </button>
          ))}
        </div>
        <div className={style.container__albums}>
          {data?.items
            .filter(({ album_type }) => !includeGroups || album_type === includeGroups)
            .map((album, index) => (
            <div key={index} className={style.album__col}>
              <Album {...album} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}