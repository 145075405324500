import cn from 'classnames';
import { Social } from '../types/utils';

// CSS
import style from '../css/modules/Footer.module.scss';

// Icons
import { FaSpotify, FaSoundcloud, FaXTwitter } from 'react-icons/fa6';
import { SiApplemusic, SiBeatport } from 'react-icons/si';
import { FaFacebook, FaInstagram, FaYoutube, FaLinkedin } from 'react-icons/fa';


// Now
const year = new Date().getFullYear();

// Social
const socials: Social[] = [
  {
    id: 'facebook',
    label: 'Facebook',
    href: 'https://www.facebook.com/MatteoManna',
    icon: FaFacebook
  },
  {
    id: 'instagram',
    label: 'Instagram',
    href: 'https://www.instagram.com/matteomanna',
    icon: FaInstagram
  },
  {
    id: 'x',
    label: 'X',
    href: 'https://x.com/MatteoManna',
    icon: FaXTwitter
  },
  {
    id: 'youtube',
    label: 'YouTube',
    href: 'https://www.youtube.com/@MatteoManna87',
    icon: FaYoutube
  },
  {
    id: 'linkedin',
    label: 'LinkedIn',
    href: 'https://www.linkedin.com/in/matteomanna',
    icon: FaLinkedin
  },
  {
    id: 'spotify',
    label: 'Spotify',
    href: 'https://open.spotify.com/intl-it/artist/7MVLJWsbVeEPgZZbt12a3h',
    icon: FaSpotify
  },
  {
    id: 'soundcloud',
    label: 'SoundCloud',
    href: 'https://soundcloud.com/matteomanna',
    icon: FaSoundcloud
  },
  {
    id: 'applemusic',
    label: 'Apple Music',
    href: 'https://music.apple.com/it/artist/matteo-manna/1446955221',
    icon: SiApplemusic
  },
  {
    id: 'beatport',
    label: 'BeatPort',
    href: 'https://www.beatport.com/artist/matteo-manna/738051',
    icon: SiBeatport
  }
];

export default function Footer() {
  return (
    <footer className={cn(style.container, 'py-5')}>
      <div className={style.container__social}>
        {socials.map(({ id, label, href, icon: Icon }, index) => (
          <a
            key={index}
            href={href}
            className={style['social-link']}
            target="_blank"
            rel="noreferrer"
            aria-label={'Matteo Manna su ' + label}
          >
            <Icon className={cn(style['social-icon'], style[id])} />
          </a>
        ))}
      </div>
      <p className={cn(style.content, 'container')}>&copy; {year} Matteo Manna - Tutti i diritti riservati</p>
    </footer>
  );
}