// CSS
import style from '../css/modules/Loading.module.scss';

// Icons
import { ImSpinner3 } from 'react-icons/im';

export default function Loading() {
  return (
    <div className={style.container}>
      <ImSpinner3 className={style.spinner} />
    </div>
  );
}