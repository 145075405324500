import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import parse from 'html-react-parser';
import { Album as Props } from '../types/spotify';
import { getImage, albumTypes } from '../utils/lib';

// CSS
import style from '../css/modules/Album.module.scss';

// Dynamic component
const AlbumSidebar = React.lazy(() => import('./AlbumSidebar'));

export default function Album({ id, name, images, release_date, album_type }: Props) {
  const { albumId } = useParams();
  const navigate = useNavigate();
  const [showSidebar, setShowSidebar] = useState(albumId === id);
  const link = `/album/${id}`;

  // Settings
  const image = getImage(images, 640);
  const release_year = new Date(release_date).getFullYear();
  const { value_singular: albumType } = albumTypes.find(({ key }) => key === album_type) || {};

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setShowSidebar(!showSidebar);
    navigate(link);
  };

  useEffect(() => {
    document.body.style.overflow = showSidebar ? 'hidden' : 'auto';
  }, [showSidebar]);

  return (
    <>
      <article className={style.container}>
        {image &&
          <figure className={style.figure}>
            <a
              href={link}
              onClick={e => handleClick(e)}
              className={style.link}
            >
              <img
                src={image.url}
                alt={name}
                width={image.width}
                height={image.height}
                className={style.image}
              />
            </a>
          </figure>
        }
        <h3 className={style.title}>
            <a
              href={link}
              onClick={e => handleClick(e)}
              className={style.link}
            >
              {parse(name)}
            </a>
        </h3>
        <footer className={style.footer}>
          <span>{release_year}</span>
          {albumType &&
            <span>{albumType}</span>
          }
        </footer>
      </article>
      {showSidebar &&
        <AlbumSidebar
          setShowSidebar={setShowSidebar}
          albumId={id}
        />
      }
    </>
  );
}