import { useState } from 'react';
import { useQuery } from 'react-query';
import cn from 'classnames';
import { fetchTopTracks } from '../services/spotify';
import Track from './Track';
import Loading from './Loading';

// CSS
import style from '../css/modules/TopTracks.module.scss';

const LIMIT = 5;
  
export default function TopTracks() {
  const [limit, setLimit] = useState<number | null>(LIMIT);
  const { data, isLoading } = useQuery('topTracksData', fetchTopTracks);

  const handleClick = () => setLimit(limit ? null : LIMIT);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <section className={cn(style.container, 'py-5')}>
      <div className="container">
        <h2 className="title-1">Popolari</h2>
        <div className={style.container__tracks}>
          {data?.tracks
            .slice(0, limit || data.tracks.length)
            .map((track, index) => (
              <Track key={index} {...track} />
            )
          )}
        </div>
        <button onClick={handleClick} className={style.button}>
          {limit ? 'Mostra di più' : 'Mostra meno'}
        </button>
      </div>
    </section>
  );
}