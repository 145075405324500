import axios from 'axios';
import { fetchAccessToken } from './spotify';

export const instance = axios.create({
  baseURL: process.env.REACT_APP_SPOTIFY_API_BASE_URI,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
});

const ACCESS_TOKEN = 'access_token';
const EXPIRES_AT = 'expires_at';

instance.interceptors.request.use(
  async (config) => {
    const accessToken = sessionStorage.getItem(ACCESS_TOKEN);
    const expiresAt = sessionStorage.getItem(EXPIRES_AT);
    const now = Date.now();

    if (accessToken && now < Number(expiresAt)) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    } else {
      try {
        const { access_token, expires_in } = await fetchAccessToken();
        
        sessionStorage.setItem(ACCESS_TOKEN, access_token);
        sessionStorage.setItem(EXPIRES_AT, (now + (expires_in * 1000)).toString());

        config.headers['Authorization'] = `Bearer ${access_token}`;
      } catch (err) {
        console.error(err);
      }
    }

    return config;
  },
  (err) => {
    Promise.reject(err);
  }
);