import parse from 'html-react-parser';
import { Track as Props } from '../types/spotify';
import { getImage, millisToMinutes } from '../utils/lib';
import ExtLink from './ExtLink';

// CSS
import style from '../css/modules/Track.module.scss';

export default function Track({ name, external_urls: { spotify: href }, album, track_number, artists, duration_ms }: Props) {
  const image = album ? getImage(album.images, 64) : undefined;

  return (
    <div className={style.container}>
      <div className={style.container__left}>
        {image ? (
          <figure className={style.figure}>
            <ExtLink href={href}>
              <img
                src={image.url}
                alt={name}
                width={image.width}
                height={image.height}
              />
            </ExtLink>
          </figure>
        ) : (
          <div className={style['track-number']}>{track_number}</div>
        )}
        <div>
          <h3 className={style.title}>
            <ExtLink href={href}>
              <span>{parse(name)}</span>
            </ExtLink>
          </h3>
          <div className={style.container__artist}>
            {artists?.map(({ name }, index) => (
              <span key={index} className={style.artist}>{parse(name)}</span>
            ))}
          </div>
        </div>
      </div>
      <div className={style.container__right}>
        <span className={style.duration}>{millisToMinutes(duration_ms)}</span>
      </div>
    </div>
  );
}