import { useQuery } from 'react-query';
import parse from 'html-react-parser';
import cn from 'classnames';
import { fetchArtist } from '../services/spotify';
import { getImage } from '../utils/lib';

// CSS
import style from '../css/modules/Header.module.scss';

// Icons
import { MdVerified } from 'react-icons/md';

// Images
import imageJpg from '../images/cover.jpg';
import imageWebp from '../images/cover.webp';

const imagePlaceholder = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEXMzMzKUkQnAAAACklEQVQI12NgAAAAAgAB4iG8MwAAAABJRU5ErkJggg==';

export default function Header() {
  const { data } = useQuery('artistData', fetchArtist);
  const image = data ? getImage(data.images, 320) : undefined;

  return (
    <header className={style.container}>
      <picture className={style.picture}>
        <source
          srcSet={imageWebp}
          type="image/webp"
        />
        <source
          srcSet={imageJpg}
          type="image/jpeg"
        />
        <img
          src={imageJpg}
          alt={'Immagine di copertina di ' + data?.name}
          width={1500}
          height={600}
        />
      </picture>
      <div className={cn(style.wrapper, 'container')}>
        <figure className={style['figure']}>
          <img
            src={image?.url ?? imagePlaceholder}
            alt={data?.name}
            width={image?.width ?? 320}
            height={image?.height ?? 320}
            className={style['image']}
          />
        </figure>
        <h1 className={style.title}>
          {data?.name &&
            <>
              <span>{parse(data.name)}</span>
              <MdVerified
                title="Artista verificato"
                className={style.badge}
              />
            </>
          }
        </h1>
      </div>
    </header>
  );
}