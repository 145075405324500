import axios, { AxiosRequestConfig } from 'axios';
import { instance } from './axios';
import { AccessToken, Artist, TopTracks, Albums, Album } from '../types/spotify';

const URL_SPOTIFY_ARTIST = '/v1/artists/' + process.env.REACT_APP_SPOTIFY_ARTIST_ID;

const defaultConfig: AxiosRequestConfig = {
  params: {
    market: 'IT'
  }
};

export const fetchAccessToken = async () => {
  const body = {
    grant_type: 'client_credentials'
  };
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + btoa(process.env.REACT_APP_SPOTIFY_CLIENT_ID + ':' + process.env.REACT_APP_SPOTIFY_CLIENT_SECRET)
    }
  };
  const { data } = await axios.post<AccessToken>(process.env.REACT_APP_SPOTIFY_ACCOUNTS_BASE_URI + '/api/token', body, config);
  return data;
};

export const fetchArtist = async () => {
  const { data } = await instance.get<Artist>(URL_SPOTIFY_ARTIST);
  return data;
};

export const fetchTopTracks = async () => {
  const { data } = await instance.get<TopTracks>(URL_SPOTIFY_ARTIST + '/top-tracks', defaultConfig);
  return data;
};

export const fetchAlbums = async () => {
  const config: AxiosRequestConfig = {
    ...defaultConfig,
    params: {
      ...defaultConfig.params,
      limit: 50,
      offset: 0
    }
  };
  const { data } = await instance.get<Albums>(URL_SPOTIFY_ARTIST + '/albums', config);
  return data;
};

export const fetchAlbum = async (albumId: string) => {
  const { data } = await instance.get<Album>('/v1/albums/' + albumId);
  return data;
};