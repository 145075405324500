import { Image } from '../types/spotify';
import { AlbumTypes } from '../types/spotify';

export const getImage = (images: Image[], size = 160) => images.find(({ width }) => width === size);

export const millisToMinutes = (number: number) => {
  const seconds = Number(((number % 60000) / 1000).toFixed(0));
  return Math.floor(number / 60000) + ':' + (seconds < 10 ? 0 : '') + seconds;
};

export const albumTypes: AlbumTypes[] = [
  {
    key: 'album',
    value_singular: 'Album',
    value_plural: 'Album'
  },
  {
    key: 'single',
    value_singular: 'Singolo',
    value_plural: 'Singoli'
  }
];